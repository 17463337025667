@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Skia';
  src: url('../../public/fonts/skia.ttf');
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Skia', sans-serif;
  letter-spacing: 0.08em;
}
